var editmode = false;
var checkedEmployees = [];
var floatTheadExists = false;
var ignoreScroll = false;
var addAble = true;
var employeeSelectize;
var useChronologicalTimes;

$(function () {
  initDatepicker();

  //$('#flashMessage').delay(2000).slideUp();
  $('#flashMessage').delay(4000).animate({"top": "-=70px"}, "slow");

  fixPaddings();

  // Add input validation
  $("input.integer").numeric({decimal: false, negative: false});
  $("input.decimal").numeric({decimal: ",", negative: false});

  //$('.load').click(function(evt){
  //	if (!evt.ctrlKey)
  //showLoader();
  //});
  $('.actions a').not('.delete, .copy, .add-planning, .switch-machine, .confirm').click(function (evt) {
    if (!evt.ctrlKey)
      showLoader();
  });

  $('.button.delete').each(function () {
    if ($(this).attr('onclick') != undefined) {
      $(this).attr('onclick', $(this).attr('onclick').replace('confirm(', 'confirmLoad('));
    }
  });

  $('form.submitload').submit(function () {
    showLoader();
  });

  // Fix grid height for weekplanning
  $('.overzicht.inplannen table .grid').height($('.overzicht.inplannen table').height() - $('.overzicht.inplannen table tr.title').height());

  $('#field_search').keypress(function (e) {
    if ((e.which && e.which == 13) || (e.keyCode && e.keyCode == 13)) {
      showLoader();
      $('#search').submit();
      e.preventDefault();
      return false;
    }
  });

  $("form input").keypress(function (e) {
    if ((e.which && e.which == 13) || (e.keyCode && e.keyCode == 13)) {
      $('input[type=submit].default').click();
      return false;
    } else {
      return true;
    }
  });

  // Fixed tableheaders voor ploegplanning, machineplanning, projectplanning, dagplanning en verlofplanning
  handleFloatThead();

  $(window).on('scroll', function () {
    initfloatThead();

    var scrollPosition = $(window).scrollTop();

    if (scrollPosition === 0) {
      $('.dayplanning-page .sticky-wrapper, table thead').removeClass('sticky');
    }
  });

  $(window).on('resize', function () {
    initfloatThead();
    //$(".overzicht table").floatThead('reflow');
    //$(".overzicht table").floatThead('destroy');
    fixPaddings();
  });

  $('.actions a.button, a.toggle-row').tooltipster();

  $(document).on('click', '.print-page', function (e) {
    e.preventDefault();
    window.print();
  });

  $(document).on('click', '.toggle-row', function (e) {
    e.preventDefault();

    $(this).closest('tr').toggleClass('hide-print');
    $(this).find('i').toggleClass('fa-toggle-on fa-toggle-off');
  });

  var projectNavHeight = $(document).find('#project_nav').height();
  if (projectNavHeight > 25 && $(document).find('.project_overzicht, .dayteamplanning-page').length === 0) {
    $(document).find('.content form#search').css('top', '-35px');
    $(document).find('.button.print-page').css('top', '-8px');
  }
});

function initfloatThead() {
  if (ignoreScroll === true) {
    ignoreScroll = false;
    return;
  }

  if (typeof is_presentation === 'undefined') {
      is_presentation = false;
  }

  var offset = window.pageYOffset || document.documentElement.scrollTop;
  var $table = $(".overzicht table");
  var ua = window.navigator.userAgent;
  var isMsie = ua.toLowerCase().indexOf('msie') !== -1 || ua.toLowerCase().indexOf('trident') !== -1;
  var isMachinePlanning = $(document).find('#PlanningMachineForm').length > 0;
  var isWeekPlanning = $(document).find('.week-planning').length > 0;

  if (floatTheadExists) {
    if (isWeekPlanning) {
      offset = offset + 146;
    } else {
      offset = offset + 76;
    }

    var projectNavHeight = $(document).find('#project_nav').height();
    if (projectNavHeight > 25) {
      offset += 25;
    }
  }

  if ($('.sticky-wrapper').length) {

    if (!is_presentation) {
        $('.sticky-wrapper, table thead').addClass('sticky');
        $table.floatThead({
            top: 100
        });
    } else {
        $('.sticky-wrapper, table thead').addClass('sticky');
        $table.floatThead({
            top: 50
        });
    }

    if (offset >= 200 && is_presentation) {
      $('.sticky-wrapper, table thead').addClass('sticky');

      if (isMsie && isMachinePlanning) {
        $('.floatThead-container').next('table').css('margin-top', '0');
        $('.floatThead-container').css('margin-top', '0');
      }

      if (floatTheadExists === false) {
        if (isWeekPlanning) {
          offset = 146;
        } else {
          offset = 76;
        }

        var projectNavHeight = $(document).find('#project_nav').height();
        if (projectNavHeight > 25) {
          offset += 25;
        }

        $table.floatThead({
          top: offset
        });

        ignoreScroll = true;
        floatTheadExists = true;
      }
    } else if (is_presentation) {
      if (!isWeekPlanning) {
        $('.sticky-wrapper, table thead').removeClass('sticky');
      }

      if (isMsie && isMachinePlanning) {
        $('.floatThead-container').next('table').css('margin-top', '67px');
        $('.floatThead-container').css('margin-top', '-47px');
      }

      if (floatTheadExists === true) {
        // $table.floatThead('destroy');
        floatTheadExists = false;
      }
    }
  }
}

function fixPaddings() {
  $('.overzicht.inplannen .item').each(function (index) {
    if ($(this).width() < 20) {
      $(this).addClass('nopadding');
      $(this).find('.tekst_wrapper').addClass('nopadding');
    } else {
      $(this).removeClass('nopadding');
      $(this).find('.tekst_wrapper').removeClass('nopadding');
    }
  });
}

function showLoader() {
  //$('#dvLoading .icon-wrapper').show();
  $('#dvLoading').fadeIn(100);
}

function hideLoader() {
  //$('#dvLoading .icon-wrapper').hide();
  $('#dvLoading').fadeOut(100);
}

function center(elem, offset) {
  if (isNaN(offset)) offset = 0;

  // get the screen height and width
  var windowHeight = $(window).height();
  var windowWidth = $(window).width();

  // calculate the values for center alignment
  var dialogTop = (windowHeight / 2) - (elem.height() / 2);
  var dialogLeft = (windowWidth / 2) - (elem.width() / 2);

  dialogTop = parseInt(dialogTop) + offset;
  dialogLeft = parseInt(dialogLeft);

  elem.css({top: dialogTop, left: dialogLeft});
}

function confirmLoad(msg) {
  if (confirm(msg)) {
    showLoader();
    return true;
  }
  return false;
}

function parseUrl(urlvar, value, url) {

  // init
  var newUrl = "";
  var namedVars = new Array();
  var getVars = "";
  if (url === undefined) {
    url = window.location.pathname;
  }

  // parse getVars
  var urlElements = url.split("?");
  if (urlElements.length > 1) {
    var getVars = "?" + urlElements[1];
  }

  // parse pages and named vars
  var urlElements = urlElements[0].split("/");
  for (var i = 0; i < urlElements.length; i++) {
    if (urlElements[i].indexOf(":") !== -1) {
      temp = urlElements[i].split(":");
      namedVars[temp[0]] = temp[1];
    } else if (urlElements[i] != "") {
      newUrl += "/" + urlElements[i];
    }
  }

  // update named var
  namedVars[urlvar] = value;

  // add named vars to url
  for (var index in namedVars) {
    newUrl += "/" + index + ":" + encodeURI(namedVars[index]);
  }

  // return
  var returnUrl = newUrl + getVars;
  //alert(window.location.pathname + " wordt " + returnUrl.replace("//", "/"));
  return returnUrl.replace("//", "/");

}

function ymdToDate(dateString) {
  dateString = dateString.substring(0, 10);
  var dateParts = dateString.split('-');

  return new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
}

function weekAndYearToDate(week, year) {
  var Day10 = new Date(year, 0, 10, 12, 0, 0),
    Day4 = new Date(year, 0, 4, 12, 0, 0),
    weekmSec = Day4.getTime() - Day10.getDay() * 86400000,  // 7 days in milli sec
    date = new Date(weekmSec + ((week - 1) * 7) * 86400000);

  var curr_date = date.getDate(),
    curr_month = date.getMonth() + 1, //Months are zero based
    curr_year = date.getFullYear(),
    dateString = curr_date + "-" + curr_month + "-" + curr_year;

  return dateString;

  //year = parseInt(year);
  //week = parseInt(week);
  //var date = new Date(year, 0, 1);
  //
  //var curr_date = date.getDate();
  // var curr_month = date.getMonth() + 1; //Months are zero based
  // var curr_year = date.getFullYear();
  // var dateString = curr_date + "-" + curr_month + "-" + curr_year;
  //
  //offset = 0;
  //if(dateToWeekNr(dateString) == 52)
  //	offset = 1;
  //
  //date.setDate(date.getDate() + ((week+offset-1)*7));
  //
  //curr_date = date.getDate();
  // curr_month = date.getMonth() + 1; //Months are zero based
  // curr_year = date.getFullYear();
  // dateString = curr_date + "-" + curr_month + "-" + curr_year;
  //
  //return dateString;
}

function dateToWeekNr(dateString) {
  var s = dateString.split('-');
  var d = new Date(s[2], parseInt(s[1]) - 1, parseInt(s[0]));
  return d.getWeek(1);
}

/**
 * Get the ISO week date week number
 */
Date.prototype.getWeek = function () {
  // Create a copy of this date object
  var target = new Date(this.valueOf());

  // ISO week date weeks start on monday
  // so correct the day number
  var dayNr = (this.getDay() + 6) % 7;

  // ISO 8601 states that week 1 is the week
  // with the first thursday of that year.
  // Set the target date to the thursday in the target week
  target.setDate(target.getDate() - dayNr + 3);

  // Store the millisecond value of the target date
  var firstThursday = target.valueOf();

  // Set the target to the first thursday of the year
  // First set the target to january first
  target.setMonth(0, 1);
  // Not a thursday? Correct the date to the next thursday
  if (target.getDay() != 4) {
    target.setMonth(0, 1 + ((4 - target.getDay()) + 7) % 7);
  }

  // The weeknumber is the number of weeks between the
  // first thursday of the year and the thursday in the target week
  return 1 + Math.ceil((firstThursday - target) / 604800000); // 604800000 = 7 * 24 * 3600 * 1000
}

function pad(num, size) {
  var s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
}

function dateAddDay(date) {
  date.setDate(date.getDate() + 1);
  return date;
}

function getDutchDate(date) {
  date.setDate(date.getDate());
  return date;
}

function dateAddDays(date, days) {
  date.setDate(date.getDate() + days);
  return date;
}

function dateAddMonth(date) {
  return new Date(date.getFullYear(), parseInt(date.getMonth()) + 1, date.getDate());
}

function dateToDMY(date) {
  var day = parseInt(date.getDate());
  var month = parseInt(date.getMonth()) + 1;
  var year = parseInt(date.getFullYear());

  if (day < 10) day = '0' + day;
  if (month < 10) month = '0' + month;

  return day + '-' + month + '-' + year;
}

function dateToYMD(date) {
  var day = parseInt(date.getDate());
  var month = parseInt(date.getMonth()) + 1;
  var year = parseInt(date.getFullYear());

  if (day < 10) day = '0' + day;
  if (month < 10) month = '0' + month;

  return year + '-' + month + '-' + day;
}

function isWorkDay(year, month, day) {
  var dayOfWeek = new Date(year, month-1, day).getDay();

  return dayOfWeek >= 1 && dayOfWeek <= 5;
}

function getWorkDays(month, year) {
  var daysInMonth = new Date(year, month, 0).getDate();
  var workdays = [];

  for (var i = 0; i < daysInMonth; i++) {
    if (isWorkDay(year, month, i+1)) {
      workdays.push(new Date(year, month-1, i+1));
    }
  }

  return workdays;
}

function showAjaxSpinner(jqObj) {
  jqObj.append('<i class="fa fa-cog fa-spin spinner"></i>');
}

function hideAjaxSpinner(jqObj) {
  jqObj.find('.spinner').remove();
}

function strpos(haystack, needle, offset) {
  haystack = haystack.toLowerCase();
  needle = needle.toLowerCase();
  var i = (haystack + '').indexOf(needle, (offset || 0));
  return i === -1 ? false : i;
}

function initDatepicker() {
  $('.datepicker').removeClass('hasDatepicker');
  $('.datepicker').datepicker('destroy');

  $(".datepicker").datepicker({
    dateFormat: "dd-mm-yy",
    firstDay: 1,
    showOtherMonths: true,
    dayNamesMin: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
    monthNames: ["Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"],
    monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"],
    showWeek: true,
    beforeShow: function (input, inst) {
      if ($(this).attr('readonly') == 'readonly') return false;
      $('#ui-datepicker-div').removeClass("weekpicker_table");
    }
  });
}

function handleFloatThead() {
  var $table = $(".overzicht table");

  var isWeekPlanning = $(document).find('.week-planning').length > 0;
  if (isWeekPlanning) {
    $table.floatThead({
      top: 160
    });
  }
}

function editPlanningHandler() {
  initDatepicker();

  var $editPlanningEmployeeSelect = $('#EditPlanningUser').selectize();

  if ($editPlanningEmployeeSelect[0]) {
    editPlanningEmployeeSelectize = $editPlanningEmployeeSelect[0].selectize;
    editPlanningEmployeeSelectize.setValue(planningEmployees);

    removeLeaveEmployees(editPlanningEmployeeSelectize);

    $(document).on('change', 'input#PlanningStartDate, input#PlanningEndDate', function () {
      removeLeaveEmployees(editPlanningEmployeeSelectize);
    });
  }

  // Do not allow options 'plan between' and 'remove overlap' both selected
  $('#PlanningRemoveOverlap').click(function (event) {
    if ($('#PlanningRemoveOverlap').is(':checked')) {
      $('#PlanningBetween').attr('checked', false);
    }
  });

  $('#PlanningBetween').click(function (event) {
    if ($('#PlanningBetween').is(':checked')) {
      $('#PlanningRemoveOverlap').attr('checked', false);
    }
  });

  if ($('#PlanningStartDate').length > 0) {
    dateStart = $(document).find('#PlanningStartDate').datepicker('getDate');
    dateMin = new Date(dateStart.getTime());
    dateMin.setDate(dateMin.getDate());
    var DateOptions = {
      minDate: dateMin,
      dateFormat: "dd-mm-yy",
      firstDay: 1,
      showOtherMonths: true,
      dayNamesMin: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
      showWeek: true,
      beforeShow: function () {
        if ($(this).attr('readonly') == 'readonly') return false;
      }
    };
    //$("#PlanningEndDate").datepicker("destroy");
    $("#PlanningEndDate").datepicker(DateOptions);
  }

  $("#PlanningStartDate").datepicker().change(function () {
    dateStart = $(this).datepicker('getDate');
    dateEnd = $("#PlanningEndDate").datepicker('getDate');
    dateMin = new Date(dateStart.getTime());

    //if ( dateMin < today ) { dateMin = today; }
    if (dateEnd !== null) {
      dateCur = new Date(dateEnd.getTime());
      if (dateMin > dateCur) {
        month = dateMin.getMonth() + 1;
        $("#PlanningEndDate").val(dateMin.getDate() + "-" + month + "-" + dateMin.getFullYear());
      }
    }

    dateMin.setDate(dateMin.getDate());

    var DateOptions = {
      minDate: dateMin,
      dateFormat: "dd-mm-yy",
      firstDay: 1,
      showOtherMonths: true,
      dayNamesMin: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
      showWeek: true,
      beforeShow: function () {
        if ($(this).attr('readonly') == 'readonly') return false;
      }
    };

    $("#PlanningEndDate").datepicker(DateOptions);
  });

  // Clear end time if hours input changes
  $('#PlanningHours').change(function () {
    $('#PlanningEndDate').val('');
    $('#PlanningEndHours').val('');
    $('#PlanningEndMinutes').val('');
  });

  // Clear hours input if end time changes
  $('#PlanningEndDate, #PlanningEndHours, #PlanningEndMinutes').change(function () {
    $('#PlanningHours').val('');
  });

  // Clear end date if hours set and employee check changes
  $(document).on('click', '.employeecheck', function () {
    // Reset all checkboxes, except the one which is clicked
    $(document).find('input.employeecheck:not(#' + $(this).attr('id') + ')').prop('checked', false);

    // Check/uncheck child employees
    var id = $(this).attr('id');
    if ($(this).is(':checked')) {
      $('input.employeecheck[parent="' + id + '"]').prop('checked', true);
    } else {
      $('input.employeecheck[parent="' + id + '"]').prop('checked', false);
    }
  });

  // Disable input if definitive is checked
  $('#PlanningDefinitive').change(function () {
    handlePlanningDefinitive();
  });

  handlePlanningDefinitive();
}

function handlePlanningDefinitive() {
  if ($('#PlanningDefinitive').is(':checked')) {
    $(document).find('#send_confirmation_mail').show();

    $('#PlanningHours').attr('readonly', 'readonly');
    $('#PlanningHours').addClass('inactive');

    $('#PlanningStartDate').attr('readonly', 'readonly');
    $('#PlanningStartDate').addClass('inactive');
    $('#PlanningStartHours').attr('readonly', 'readonly');
    $('#PlanningStartHours').addClass('inactive');
    $('#PlanningStartMinutes').attr('readonly', 'readonly');
    $('#PlanningStartMinutes').addClass('inactive');

    $('#PlanningEndDate').attr('readonly', 'readonly');
    $('#PlanningEndDate').addClass('inactive');
    $('#PlanningEndHours').attr('readonly', 'readonly');
    $('#PlanningEndHours').addClass('inactive');
    $('#PlanningEndMinutes').attr('readonly', 'readonly');
    $('#PlanningEndMinutes').addClass('inactive');
  } else {
    $(document).find('#send_confirmation_mail').hide();

    $('#PlanningHours').removeAttr('readonly');
    $('#PlanningHours').removeClass('inactive');

    $('#PlanningStartDate').removeAttr('readonly');
    $('#PlanningStartDate').removeClass('inactive');
    $('#PlanningStartHours').removeAttr('readonly');
    $('#PlanningStartHours').removeClass('inactive');
    $('#PlanningStartMinutes').removeAttr('readonly');
    $('#PlanningStartMinutes').removeClass('inactive');

    $('#PlanningEndDate').removeAttr('readonly');
    $('#PlanningEndDate').removeClass('inactive');
    $('#PlanningEndHours').removeAttr('readonly');
    $('#PlanningEndHours').removeClass('inactive');
    $('#PlanningEndMinutes').removeAttr('readonly');
    $('#PlanningEndMinutes').removeClass('inactive');
  }
}

function GetURLParameter(sParam) {
  var sPageURL = window.location.pathname;
  var sURLVariables = sPageURL.split('/');

  for (var i = 0; i < sURLVariables.length; i++) {
    var sParameterName = sURLVariables[i].split(':');
    if (sParameterName[0] == sParam) {
      return sParameterName[1];
    }
  }
}

function loadPlanning(url, $planningContainer, userIds, isEmployeePlanning) {
  showLoader();
  // Check if edit-mode is currently enabled
  if (editmode === true) {
    if (url !== undefined && url !== null && url.indexOf('/week/') >= 0) {
      // Store edit-view in variable
      var $editView = $('.edit_planning');
      var planningId = $('.edit_planning input#edit_planning_id').val();
    }
  }

  // If no url-parameter is provided, get planning based on browser-url
  if (url === null || url === undefined) {
    // Build url
    var url = '/plannings/ajax_week';

    if (isEmployeePlanning) {
      url = '/plannings/ajax_employee_week';
    }

    // Add numweeks if set
    if (window.location.pathname.indexOf('/numweeks:') > -1) {
      url += '/' + window.location.pathname.match(/(numweeks:.*?)(\/|$)/)[1];
    }

    // Add from if set
    if (window.location.pathname.indexOf('/from:') > -1) {
      url += '/' + window.location.pathname.match(/(from:.*?)(\/|$)/)[1];
    }

    // Add id if set
    if (window.location.pathname.indexOf('/id:') > -1) {
      url += '/' + window.location.pathname.match(/(id:.*?)(\/|$)/)[1];
    }

    // Add filter for users
    if (userIds) {
      url += '/userIds:' + userIds.join(',');
    }

    // Add query parameters if set
    if (window.location.search) {
      url += window.location.search;
    }

    var $planningContainer = $('#planningContainer');
  }

  // Load planning view
  $.ajax({
    type: 'GET',
    url: url,
    cache: false,
    success: function (data) {
      if (isJsonString(data)) {
        data = JSON.parse(data);

        if (data.redirect !== undefined) {
          window.location = data.redirect;
        }
      }

      if (userIds) {
        // Replace only rows of given users
        $.each(userIds, function (index, userId) {
          var newEmployeeRow = $(data).find('tr.employee[data-userid="' + userId + '"]');
          var newTds = newEmployeeRow.find('td:not(".first"):not(".type"):not(".name")');

          var existingEmployeeRow = $planningContainer.find('tr.employee[data-userid="' + userId + '"]');
          existingEmployeeRow.find('td:not(".first"):not(".type"):not(".name")').remove();
          existingEmployeeRow.append(newTds);
        });
      } else if ($planningContainer.is('#planningContainer')) {
        $planningContainer.empty().append(data);
      } else {
        $planningContainer.replaceWith(data);
      }

      // Append edit-view
      if (editmode === true) {
        if ($editView !== undefined) {
          $(document).find('.edit_planning').replaceWith($editView);

          // Set correct form action
          var formAction = '/plannings/ajax_week/id:' + planningId;

          if (isEmployeePlanning) {
            formAction = '/plannings/ajax_employee_week/id:' + planningId;
          }

          $('form#PlanningWeekForm').attr('action', formAction);

          editPlanningHandler();
        }

        if (checkedEmployees !== undefined) {
          // Check checkboxes of employees
          $.each(checkedEmployees, function (i, userId) {
            $(document).find('input#EmployeeCheck' + userId).prop('checked', true);
          });
        }
      } else {
        $(document).find('input.employeecheck').prop('checked', false).hide();
      }

      initDraggable();
      initAddBlock();
      loadToolTips();

      if (!userIds) {
        $(document).find('[data-toggle=tooltip]').tooltipster({
          arrow: true,
          delay: 500,
          followMouse: true,
          position: 'top',
        });

        initCombobox();
        initDatepicker();
        initWeekpicker();
        handleFloatThead();

        var projectNavHeight = $(document).find('#project_nav').height();

        $(document).find('.overzicht table#employeePlannings').floatThead({
          top: projectNavHeight > 25 ? 171 : 146
        });

        var scrollHeight = localStorage.getItem('scrollHeight');
        if (scrollHeight !== null) {
          window.pageYOffset = scrollHeight;
          document.documentElement.scrollTop = scrollHeight;
          document.body.scrollTop = scrollHeight;
          localStorage.removeItem('scrollHeight');
        }
      }

      hideLoader();
    },
    error: function (x, h, r) {
      if (x.status === 403) {
        window.location.href = '/users/logout';
      }
    }
  });
}

function collision($div1, $div2) {
  var x1 = $div1.offset().left,
    y1 = $div1.offset().top,
    h1 = $div1.height(),
    w1 = $div1.width(),
    b1 = y1 + h1,
    r1 = x1 + w1,
    x2 = $div2.offset().left,
    y2 = $div2.offset().top,
    h2 = $div2.height(),
    w2 = $div2.width(),
    b2 = y2 + h2,
    r2 = x2 + w2;

  return (y2 <= b1 && b2 >= y1) && (x2 <= r1 && r2 >= x1);
}

function initDraggable() {
  /**
   * Make planningblocks draggable
   */
  if (draggable === null || draggable === true) {
    if ($(document).find('.tt.item.ui-draggable').length) {
      $(document).find('.tt.item.ui-draggable').draggable('destroy');
    }

    $(document).find('.tt.item:not(.leave)').draggable({
      revertDuration: 10,
      snap: '.overzicht.inplannen table tbody td',
      opacity: 0.7,
      delay: 50,
      revert: 'invalid',
      zIndex: 999,
      create: function (e, ui) {
        $(this).addClass('ui-draggable');
      },
      start: function (e, ui) {
        ui.helper.addClass('selected');

        // prevent element being clicked
        $(e.toElement).on('click', function (e) {
          e.stopImmediatePropagation();
        });

        // Calculate right-property for remove_planning_area
        var offset = 0;
        $('.header_right').each(function () {
          offset += $(this).outerWidth();
        });

        $('#remove_planning_area').css('right', offset + 'px');

        if ($('#toggleMenu').hasClass('hidden')) {
          $('#remove_planning_area').css('left', '0px');
        }

        $('#remove_planning_area').fadeIn();
      },
      drag: function (e, ui) {
        $('.machine.selected').addClass('dragging');
        $('.selected').css({
          top: ui.position.top,
          left: ui.position.left,
          zIndex: 9999999,
        });
      },
      stop: function (e, ui) {
        $('.machine.selected').removeClass('dragging');
        $('#remove_planning_area').fadeOut(50);

        $('tr.employee').removeClass('employee-row-disabled');
      },
      drop: function () {
        // Make item draggable again
        if ($(this).find('.ui-draggable').length) {
          // Don't overwrite occupied spot
          return;
        }

        $(this).empty();
        $(this).append(ui.draggable);
      }
    });

    /**
     * Handle drop-event for remove-area
     */
    if ($(document).find('#remove_planning_area.ui-droppable').length) {
      $('#remove_planning_area').droppable('destroy');
    }

    $(document).find('#remove_planning_area').droppable({
      drop: function (e, ui) {
        if (confirm('Weet u zeker dat u dit item wilt verwijderen?')) {
          // Prevent element being clicked
          $(e.toElement).on('click', function (e) {
            e.stopImmediatePropagation();
          });

          var $elements = $('.machine.item.selected');
          $elements.hide();

          showLoader();

          var planningMachinesIds = [];
          $elements.each(function (index, $element) {
            var planningMachinesId = $($element).find('.edit').attr('planningMachineId');
            planningMachinesIds.push(planningMachinesId);
          });

          $(document).find('#BlockPlanningmachineId').val(planningMachinesIds.join(','));
          $(document).find('#BlockAction').val('remove');
          $(document).find('#ignore_conflicts_block').val(1);

          submitUpdateBlock();
        } else {
          loadPlanning();
        }
      }
    });

    /**
     * Handle drop-event for td's
     */
    if ($(document).find('.overzicht.inplannen table tbody td.droppable.ui-droppable').length) {
      $('.overzicht.inplannen table tbody td.droppable.ui-droppable').droppable('destroy');
    }

    $(document).find('.overzicht.inplannen table tbody td.droppable').droppable({
      drop: function (e, ui) {
        // Prevent element being clicked
        $(e.toElement).on('click', function (e) {
          e.stopImmediatePropagation();
        });

        var toElementId = $(e.toElement).attr('id');
        var toElementParentId = $(e.toElement).parent().attr('id');

        if (toElementId !== 'remove_planning_area' && toElementParentId !== 'remove_planning_area') {
          handleDropEvent(false);
        }
      }
    });
  }
}

function handleDropEvent(ignoreConflicts) {
  showLoader();

  $('#remove_planning_area').fadeOut(50);

  var $elements = $('.machine.item.selected');
  var $droppables = $('td.droppable:not(".weekend")');
  var items = [];
  var changeMachinesPopupShown = false;
  var preventPost = false;

  $elements.each(function (i, el) {
    var $draggable = $(el);
    // Find td(s) which overlap with dropped item
    var $tdsCoveredByDraggable = $droppables.filter(function () {
      var $droppable = $(this);
      return collision($draggable, $droppable);
    });

    var $td = null;
    if ($tdsCoveredByDraggable.length === 1) {
      // One match is found
      $td = $tdsCoveredByDraggable.first();
    } else if ($tdsCoveredByDraggable.length > 1) {
      // Multiple matches found, find out which td overlaps most
      var l1 = $draggable.offset().left;
      var tdl2 = null;

      $tdsCoveredByDraggable.each(function () {
        var l2 = $(this).offset().left;
        var diff = Math.abs(l2 - l1);

        if ($td === null || diff < tdl2) {
          tdl2 = diff;
          $td = $(this);
        }
      });
    } else {
      // No match found
      return;
    }

    // Get new values
    var hIndex = $td.index() + 1;
    var vIndex = $td.closest('tr').index() + 1;
    var machineId = $('.overzicht.inplannen table tbody tr.employee:nth-child(' + vIndex + ') td.name').data('userid');
    var startDate = $('.overzicht.inplannen table tr.title th:nth-child(' + hIndex + ')').data('date');
    var editField = $(el).find('.edit');
    var oldUserId = parseInt(editField.attr('machineId'));
    var oldStartDate = editField.attr('startdate');
    var numMachines = parseInt(editField.attr('nummachines'));

    // Ask user if attached machines need to be changed as well
    if (startDate !== oldStartDate) {
      if ($($td).data('workday') === 'no' && !ignoreConflicts) {
        hideLoader();

        // Show conflicts
        $('#conflictsContainer').empty().append('<div id="conflicts"><div><input type="hidden" name="edit_type" id="edit_type" value="plan"><span class="title">Gevonden conflicten</span><table><tr> <td class="left_text">Planning valt op/binnen ' + $($td).data('title') + '</td> <td class="middle">&nbsp;</td> <td class="right_text">' + $($td).data('startdate') + ' t/m ' + $($td).data('enddate') + '</td> </tr> </table><a href="" class="button ignore">Negeren</a><a href="" class="button cancel">Annuleren</a></div></div>');
        $('#conflicts').fadeIn();

        // Bind conflicts buttons
        $('#conflicts .button.ignore').click(function () {
          handleDropEvent(true);
        });

        $('#conflicts .button.cancel').click(function () {
          loadPlanning();
        });

        // Center conflicts
        center($('#conflicts div'), -50);

        preventPost = true;
      }

      if (($($td).data('workday') !== 'no' || ignoreConflicts) && changeMachinesPopupShown === false) {
        hideLoader();

        if (numMachines > 0) {
          changeMachinesConfirm(function (changeMachinesAction) {
            var url = '/api/plannings/save/' + changeMachinesAction;
            dropEventCallback(url, items);
          });

          changeMachinesPopupShown = true;
          preventPost = true;
        }
      }
    }

    if ($($td).data('workday') !== 'no' || ignoreConflicts) {
      items.push({
        'planningMachineId': editField.attr('planningMachineId'),
        'planningId': $(el).find('input#planningId').val(),
        'machineId': machineId,
        'oldUserId': oldUserId,
        'oldStartDate': oldStartDate,
        'startDate': startDate,
        'ignoreConflicts': ignoreConflicts === true ? '1' : '0',
        'startHours': editField.attr('starthours'),
        'startMinutes': editField.attr('startminutes'),
        'endHours': editField.attr('endhours'),
        'endMinutes': editField.attr('endminutes'),
        'changeMachines': $(document).find('input#change-machines').val(),
        'predictedEmployeeCount': editField.attr('planningPrediction'),
      });
    }
  });

  // Stop processing
  if (preventPost) {
    return;
  }

  dropEventCallback('/api/plannings/save', items);
}

function dropEventCallback(url, items) {
  $.ajax({
    type: 'POST',
    url: url,
    dataType: 'json',
    data: {
      data: items
    },
    success: function (result) {
      if (result.status === 'OK') {
        var userIds = [];

        $.each(items, function (index, item) {
          $($(document).find('#EmployeeCheck' + item.oldUserId)).prop('checked', false);

          checkedEmployees = jQuery.grep(checkedEmployees, function (value) {
            return value !== item.oldUserId.toString();
          });

          checkedEmployees.push(item.machineId.toString());

          if (userIds.indexOf(item.oldUserId) === -1) {
            userIds.push(item.oldUserId);
          }

          if (userIds.indexOf(item.machineId) === -1) {
            userIds.push(item.machineId);
          }
        });

        // Refresh page
        loadPlanning(null, null);

        if (result.hasOwnProperty('employeeAvailability')) {
            $('tr.employees.totals td.even, tr.employees.totals td.odd').each(function(key, element) {
              if (result.employeeAvailability[key]) {
                var day = Object.keys(result.employeeAvailability[key])[0];

                $(element).find('span.planned span').text(result.employeeAvailability[key][day]['planned'] + ' / ' + result.employeeAvailability[key][day]['total']);
                $(element).find('span.available span').text(result.employeeAvailability[key][day]['total'] - result.employeeAvailability[key][day]['planned'] + ' / ' + result.employeeAvailability[key][day]['total']);
              }
            })
        }
      } else {
          if ($(result.html).filter('#conflicts').length) {
              // Show conflicts
              $('#conflictsContainer').empty().append(result.html);
              $('#conflicts').fadeIn();

              // Center conflicts
              center($('#conflicts div'), -50);
          }
      }
    },
    error: function (xhr, status, error) {
      $('.planning.item.selected').removeClass('selected');
      hideLoader();

      try {
        var result = JSON.parse(xhr.responseText);

        alert(result.messages.toString());
      } catch (error) {
        alert('Er is iets misgegaan');
      }
    }
  });
}

function changeMachinesConfirm(cb) {
  hideLoader();

  // Show confirmation
  var $confirmation = $(document).find('.change-machines-confirmation');

  $confirmation.show();
  center($confirmation.find('> div'));

  // Bind events to confirmation-buttons
  $(document).off('click', '.button.change-machines.all').on('click', '.button.change-machines.all', function (e) {
    e.preventDefault();

    $confirmation.hide();
    cb('all');
  });

  $(document).off('click', '.button.change-machines.only_not_attached').on('click', '.button.change-machines.only_not_attached', function (e) {
    e.preventDefault();

    $confirmation.hide();
    cb('only_not_attached');
  });

  $(document).off('click', '.button.change-machines.no').on('click', '.button.change-machines.no', function (e) {
    e.preventDefault();

    $confirmation.hide();
    cb('no');
  });
}

/**
 * Get the correct daynumber when week starts on monday (day 0)
 * @param date
 * @returns {number}
 */
function getDayNumber(date) {
  if (Object.prototype.toString.call(date) !== "[object Date]") {
    // assume date is of format dd-mm-yyyy!
    date = date.split('-');
    date = new Date(date[2], date[1] - 1, date[0]);
  }
  var dayNumber = date.getDay() - 1;
  if (dayNumber == -1) {
    dayNumber = 7;
  }

  return dayNumber;
}

function initAddBlock() {
  if (addAble == null || addAble == true) {
    setTimeout(function () {
      $('#block-add select').selectize({
        plugins: ['remove_button'],
      });
    }, 200);

    $(document).off('click', 'table td .item.empty').on('click', 'table td .item.empty,.ui-droppable', function (event) {
      var thisElement = this;

      if ($(this).find('.item.empty')[0] !== undefined) {
        thisElement = $(this).find('.item.empty')[0];
      }

      if ($(event.currentTarget.firstElementChild).hasClass('tt')) {
        return;
      }

      // Position editor
      $('#block-add').css({
        height: 'auto'
      });

      // Load loader and show block
      $('#dvLoading').fadeIn(100);
      $('#dvLoading .icon-wrapper').hide();
      $('#block-add').fadeIn(100);

      $machineSelect = $('#PlanningMachineId').selectize();
      var machineSelectize = $machineSelect[0].selectize;
      var machine = $(thisElement).closest('tr').find('td.name').attr('data-userid');
      machineSelectize.setValue(machine);

      var $employeeSelect = $('#AddBlockEmployee').selectize();
      employeeSelectize = $employeeSelect[0].selectize;

      $('#AddLeaveRequestEmployee').selectize();

      var td = $(thisElement).parent();
      var th = $('.overzicht.inplannen table').find('tr.title th').eq(td.index());
      var th_date = th.attr('data-date');
      var date_parts = th_date.split('-');
      var date = new Date(date_parts[2], date_parts[1], date_parts[0]);

      $('.AddBlockStartDate').val(date_parts[0] + '-' + date_parts[1] + '-' + date_parts[2]);

      if (useChronologicalTimes) {
        var numPlannings = $(this).closest('td').find('.machine.item').length;
        var startTimeHours = '0' + numPlannings;

        $(".AddBlockStartHours").val(startTimeHours);
        $(".AddBlockStartMinutes").val('00');
        $(".AddBlockEndHours").val(startTimeHours);
        $(".AddBlockEndMinutes").val('59');
      } else {
        var workingHoursStartTime = workingHours[getDayNumber($(".AddBlockStartDate").val())]['Day']['day_start_time'];
        workingHoursStartTime = workingHoursStartTime.split(":");

        var workingHoursEndTime = workingHours[getDayNumber($(".AddBlockStartDate").val())]['Day']['day_end_time'];
        workingHoursEndTime = workingHoursEndTime.split(":");

        $(".AddBlockStartHours").val(workingHoursStartTime[0]);
        $(".AddBlockStartMinutes").val(workingHoursStartTime[1]);
        $(".AddBlockEndHours").val(workingHoursEndTime[0]);
        $(".AddBlockEndMinutes").val(workingHoursEndTime[1]);
      }

      // Set employee
      var userId = td.parent().find('td.name').data('userid');
      $('#AddBlockEmployee').val(userId);
      $('#AddLeaveRequestEmployee').val(userId);

      // Set holiday data
      if ($(td).data('workday') === 'no') {
        var addBlock = $('#block-add');
        var title = $(td).attr('data-title');
        var workday = $(td).attr('data-workday');
        var startdate = $(td).attr('data-startdate');
        var enddate = $(td).attr('data-enddate');

        addBlock.attr('data-workday', workday);
        addBlock.attr('data-title', title);
        addBlock.attr('data-startdate', startdate);
        addBlock.attr('data-enddate', enddate);
      }

      removeLeaveEmployees(employeeSelectize);

      $(document).on('change', 'input.AddBlockStartDate', function() {
        removeLeaveEmployees(employeeSelectize);
      });

      event.preventDefault();

      return false;
    });

    $('#block-add a').click(function (event) {
      if ($(this).hasClass('cancel') || $(this).hasClass('close')) {
        // Reset form
        $('#FormAddBlock')[0].reset();
        $('#predictEmployeeCount').addClass('hidden');

        // Clear selectize values
        var $machineSelect = $('#PlanningMachineId').selectize();
        var machineSelectize = $machineSelect[0].selectize;
        var $employeeSelect = $('#AddBlockEmployee').selectize();
        var employeeSelectize = $employeeSelect[0].selectize;
        var $projectSelect = $('#PlanningProjectId').selectize();
        var projectSelectize = $projectSelect[0].selectize;

        machineSelectize.clear();
        employeeSelectize.clear();
        projectSelectize.clear();

        // Hide popup
        $('#dvLoading img').hide();
        $('#dvLoading').fadeOut(100);
        $('#block-add').fadeOut(100);
      } else if ($(this).hasClass('save-add')) {
        if ($(this).hasClass('disabled')) {
          $('#dvLoading').fadeIn(100);
          $('#dvLoading .icon-wrapper').hide();

          if ($('#PlanningProjectId').val() == '' || $('#PlanningProjectId option').length == 0) {
            $('#PlanningProjectId').next('.selectize-control').find('.selectize-input').addClass('form-error');
          } else {
            $('#PlanningProjectId').next('.selectize-control').find('.selectize-input').removeClass('form-error');
          }

          // Check if hours are filled in
          if ($('.AddBlockStartHours').val() == '') {
            $('.AddBlockStartHours').addClass('form-error');
          } else {
            $('.AddBlockStartHours').removeClass('form-error');
          }

          // Check if hours are filled in
          if ($('.AddBlockEndHours').val() == '') {
            $('.AddBlockEndHours').addClass('form-error');
          } else {
            $('.AddBlockEndHours').removeClass('form-error');
          }

          if ($('.AddBlockStartDate').val() === '' || $('.AddBlockStartDate').val() === $('.AddBlockStartDate').attr('data-duplicated-value')) {
            $('.AddBlockStartDate').addClass('form-error');
          } else {
            $('.AddBlockStartDate').removeClass('form-error');
          }
          return false;
        }

        var block = $('#block-add');
        if (block.data('workday') === 'no') {
          hideLoader();

          // Show conflicts
          $('#conflictsContainer').empty().append('<div id="conflicts"><div><input type="hidden" name="edit_type" id="edit_type" value="plan"><span class="title">Gevonden conflicten</span><table><tr> <td class="left_text">Planning valt op/binnen ' + block.data('title') + '</td> <td class="middle">&nbsp;</td> <td class="right_text">' + block.data('startdate') + ' t/m ' + block.data('enddate') + '</td> </tr> </table><a href="" class="button ignore">Negeren</a><a href="" class="button cancel">Annuleren</a></div></div>');
          $('#conflicts').fadeIn();

          // Bind conflicts buttons
          $('#conflicts .button.ignore').click(function () {
            showLoader();
            $('#FormAddBlock').submit();
          });
          $('#conflicts .button.cancel').click(function () {
            loadPlanning();
          });

          // Center conflicts
          center($('#conflicts div'), -50);

          var scrollHeight = window.scrollY || window.scollTop || document.getElementsByTagName('html')[0].scrollTop;
          localStorage.setItem('scrollHeight', scrollHeight);
          $('#AddBlockAction').val('save');
          block.hide();
        } else {
          var scrollHeight = window.scrollY || window.scollTop || document.getElementsByTagName('html')[0].scrollTop;
          localStorage.setItem('scrollHeight', scrollHeight);
          $('#AddBlockAction').val('save');
          $('#AddBlock-add').fadeOut(100);

          // Ignore conflicts automatically when default times are used
          var startTimeHours = $(document).find('.AddBlockStartHours').val();
          var startTimeMinutes = $(document).find('.AddBlockStartMinutes').val();
          var endTimeHours = $(document).find('.AddBlockEndHours').val()
          var endTimeMinutes = $(document).find('.AddBlockEndMinutes').val()

          if (startTimeHours === '00' && startTimeMinutes === '00' && endTimeHours === '23' && endTimeMinutes === '59') {
            $('#ignore_conflicts_add').val('1');
          }

          showLoader();
          postDataAttempt();

          function postDataAttempt(isRecursiveCall) {
            if (typeof isRecursiveCall === undefined) {
                isRecursiveCall = false;
            }

            var url = $('#FormAddBlock')[0].action;
            var type = $('#FormAddBlock')[0].method;
            var data = $('#FormAddBlock').serialize();

            $.ajax({
                url: url,
                type: type,
                data: data,
                context: $('#conflictsContainer'),
                success: function (response) {
                    $('#dvLoading img').hide();
                    $('#dvLoading').fadeOut(100);
                    $('#block-add').fadeOut(100);

                    if (response.html !== undefined) {
                        $(this).empty().append(response.html);
                        center($('#conflicts div'), -50);
                        $('#conflicts').fadeIn();
                    } else {
                        // Response was not a conflict html item, reload page to update table
                        $('#conflictsContainer').empty();
                        location.reload();
                    }

                    $('#conflicts .button.ignore').click(function () {
                        showLoader();
                        $('#ignore_conflicts_add').val('1');

                        if (!isRecursiveCall) {
                            $('#conflictsContainer').empty();
                            postDataAttempt(true);
                        }
                    });
                }
            });
          }
        }
      } else if ($(this).hasClass('add-leave-request')) {
        if ($(this).hasClass('disabled')) {
          $('#dvLoading').fadeIn(100);
          $('#dvLoading .icon-wrapper').hide();

          if ($('#LeaveRequestLeaveTypeId').val() == '' || $('#LeaveRequestLeaveTypeId option').length == 0) {
            $('#LeaveRequestLeaveTypeId').next('.selectize-control').find('.selectize-input').addClass('form-error');
          } else {
            $('#LeaveRequestLeaveTypeId').next('.selectize-control').find('.selectize-input').removeClass('form-error');
          }

          if ($('.AddBlockStartDate').val() === '') {
            $('.AddBlockStartDate').addClass('form-error');
          } else {
            $('.AddBlockStartDate').removeClass('form-error');
          }

          return false;
        }

        showLoader();

        $('#LeaveRequestAjaxAddForm').submit();
      }

      event.preventDefault();
      return false;
    });

    $('#PlanningProjectId, .AddBlockStartHours, .AddBlockEndHours').on('change', function () {
      if ($('#PlanningProjectId').val() === '' ||
        $('#PlanningProjectId').val() === null ||
        $('.AddBlockStartHours').val() === '' ||
        $('.AddBlockEndHours').val() === ''
      ) {
        $('.save-add').addClass('disabled');
      } else {
        $('.save-add').removeClass('disabled');
      }

      if ($(this).val() !== '' && $(this).val() !== null) {
        $(this).next('.selectize-control').find('.selectize-input').removeClass('form-error');
      }
    });

    $('#LeaveRequestLeaveTypeId').change(function () {
      if ($(this).val() == '') {
        $('.add-leave-request').addClass('disabled');
      } else {
        $('#LeaveRequestLeaveTypeId').removeClass('form-error');
        $('.add-leave-request').removeClass('disabled');
      }
    });
  }
}

/**
 * Return true if given string is valid JSON, otherwise returns false
 *
 * @param str
 * @returns {boolean}
 */
function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }

  return true;
}

function removeLeaveEmployees(selectizeInstance) {
  var options = [];
  var selectedOptions = selectizeInstance.getValue();

  Object.keys(employees).forEach(function (employeeId) {
    var employee = employees[employeeId];
    var isEditMode = !$(document).find('.edit_planning').hasClass('hide');
    var employeeEnabled = true;

    if (selectedOptions.indexOf(employeeId) === -1) {
      if (employee.deleted) {
        employeeEnabled = false;
      } else if (employee.contract_start_date || employee.contract_end_date) {
        if (isEditMode) {
          employeeEnabled = checkContractDatesForEditPlanning(employee.contract_start_date, employee.contract_end_date);
        } else {
          employeeEnabled = checkContractDatesForAddBlock(employee.contract_start_date, employee.contract_end_date);
        }
      } else if (employee.is_temporary) {
        // Check available days
        if (Object.values(employee.availableDays).length > 0) {
          if (isEditMode) {
            employeeEnabled = checkAvailableDaysForEditPlanning(Object.values(employee.availableDays));
          } else if ($('#content_wrapper.dayplanning-page').length) {
            employeeEnabled = checkAvailableDaysForDayPlanning(Object.values(employee.availableDays));
          } else {
            employeeEnabled = checkAvailableDaysForAddBlock(Object.values(employee.availableDays));
          }
        } else {
          employeeEnabled = false;
        }
      } else {
        for (var i = 0; i < Object.keys(employee.leaveRequests).length; i++) {
          if (employeeEnabled === false) {
            continue;
          }

          var leaveRequest = employee.leaveRequests[i];

          if (leaveRequest.LeaveStatus.name === 'Akkoord') {
            if (isEditMode) {
              employeeEnabled = checkLeaveRequestForEditPlanning(leaveRequest);
            } else {
              employeeEnabled = checkLeaveRequestForAddBlock(leaveRequest);
            }
          }
        }
      }
    }

    if (employeeEnabled) {
      options.push({
        text: employee.name,
        value: employeeId
      });
    }
  });

  selectizeInstance.clear();
  selectizeInstance.clearOptions();
  selectizeInstance.load(function(callback) {
      callback(options);
      selectizeInstance.setValue(selectedOptions);
  });
}

function checkLeaveRequestForAddBlock(leaveRequest) {
  var selectedDate;

  if ($(document).find('#PlanningDay').length) {
    selectedDate = $(document).find('#PlanningDay').val();
    selectedDate = ymdToDate(selectedDate);
  } else {
    selectedDate = $(document).find('.AddBlockStartDate').datepicker('getDate');
  }

  var startTime = ymdToDate(leaveRequest.LeaveRequest.start_time);
  var endTime = null;

  if (leaveRequest.LeaveRequest.end_time) {
    endTime = ymdToDate(leaveRequest.LeaveRequest.end_time);
  }

  // Check if selected date is between start- and endtime
  if (startTime && endTime) {
    if (selectedDate >= startTime && selectedDate <= endTime) {
      return false;
    }
  } else if (selectedDate >= startTime) {
    return false;
  }

  return true;
}

function checkContractDatesForAddBlock(contractStartDate, contractEndDate) {
  if ($(document).find('.plan-once .AddBlockStartDate').is(':visible')) {
    var selectedDate = dateToYMD($(document).find('.plan-once .AddBlockStartDate').datepicker('getDate'));
  } else {
    var selectedDate = new Date($(document).find('input#PlanningDay').val());
  }

  if (contractStartDate && (selectedDate < ymdToDate(contractStartDate))) {
    return false;
  }

  if (contractEndDate && (selectedDate > ymdToDate(contractEndDate))) {
    return false;
  }

  return true;
}

function checkAvailableDaysForAddBlock(availableDays) {
  if ($(document).find('.plan-once .AddBlockStartDate').is(':visible')) {
    var selectedDate = dateToYMD($(document).find('.plan-once .AddBlockStartDate').datepicker('getDate'));
  } else {
    var selectedDate = new Date($(document).find('input#PlanningDay').val());
  }

  return availableDays.indexOf(selectedDate) !== -1
}

function checkAvailableDaysForDayPlanning(availableDays) {
  var selectedDate = $(document).find('#PlanningDay').val();

  return availableDays.indexOf(selectedDate) !== -1
}

function checkLeaveRequestForEditPlanning(leaveRequest) {
  var selectedStartDate = $(document).find('#PlanningStartDate').datepicker('getDate');
  var selectedEndDate = $(document).find('#PlanningEndDate').datepicker('getDate');
  var startTime = ymdToDate(leaveRequest.LeaveRequest.start_time);
  var endTime = null;

  if (leaveRequest.LeaveRequest.end_time) {
    endTime = ymdToDate(leaveRequest.LeaveRequest.end_time);
  }

  if (!selectedStartDate) {
    return false;
  }

  // Here we check if the selected start- and end date overlaps with the leave request's start- and end date (both end dates are optional)
  if (selectedStartDate && selectedEndDate) {
    if (startTime && endTime) {
      if (
          (selectedStartDate <= startTime && startTime <= selectedEndDate) ||
          (selectedStartDate <= endTime && endTime <= selectedEndDate) ||
          (startTime < selectedStartDate && selectedEndDate < endTime)
      ) {
        return false;
      }
    } else if (
        (selectedStartDate <= startTime && startTime <= selectedEndDate) ||
        startTime <= selectedStartDate
    ) {
      return false;
    }
  } else {
    if (startTime && endTime) {
      if (selectedStartDate >= startTime && selectedStartDate <= endTime) {
        return false;
      }
    } else if (selectedStartDate >= startTime) {
      return false;
    }
  }

  return true;
}

function checkContractDatesForEditPlanning(contractStartDate, contractEndDate) {
  var selectedStartDate = $(document).find('#PlanningStartDate').datepicker('getDate');
  var selectedEndDate = $(document).find('#PlanningEndDate').datepicker('getDate');

  if (selectedStartDate) {
    if (contractStartDate && selectedStartDate < ymdToDate(contractStartDate)) {
      return false;
    }

    if (contractEndDate && selectedStartDate > ymdToDate(contractEndDate)) {
      return false;
    }
  }

  if (selectedEndDate) {
    if (contractEndDate && selectedEndDate > ymdToDate(contractEndDate)) {
      return false;
    }
  }

  return true;
}

function checkAvailableDaysForEditPlanning(availableDays) {
  var selectedStartDate = $(document).find('#PlanningStartDate').datepicker('getDate');
  var selectedEndDate = $(document).find('#PlanningEndDate').datepicker('getDate');
  var isAvailable;

  if (!selectedStartDate) {
    return false;
  }

  // Here we check if the user is available in the selected start- and end date period
  if (selectedStartDate && selectedEndDate && (dateToYMD(selectedStartDate) !== dateToYMD(selectedEndDate))) {
    isAvailable = true;

    while (selectedStartDate <= selectedEndDate) {
      isAvailable = availableDays.indexOf(dateToYMD(selectedStartDate)) !== -1;
      selectedStartDate = dateAddDay(selectedStartDate);
    }
  } else {
    isAvailable = availableDays.indexOf(dateToYMD(selectedStartDate)) !== -1;
  }

  return isAvailable;
}

function isEditing() {
  return ($(document).find('.edit_planning').length > 0 && $(document).find('.edit_planning').is(':visible')) ||
      ($(document).find('#block-add').length > 0 && $(document).find('#block-add').is(':visible')) ||
      ($(document).find('#ui-datepicker-div').length > 0 && $(document).find('#ui-datepicker-div').is(':visible')) ||
      ($(document).find('.remarks-form').length > 0 && $(document).find('.remarks-form').is(':visible')) ||
      ($(document).find('.modal').length > 0 && $(document).find('.modal').is(':visible'));
}
